// Generated by Framer (1e08357)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["d6eAgBt_I"];

const serializationHash = "framer-N2Jnu"

const variantClassNames = {d6eAgBt_I: "framer-v-1oj2ru5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "d6eAgBt_I", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1oj2ru5", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"d6eAgBt_I"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7RE0gU2Fucy1yZWd1bGFy", "--framer-font-family": "\"DM Sans\", \"DM Sans Placeholder\", sans-serif", "--framer-letter-spacing": "-0.16px", "--framer-line-height": "130%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>© 2024 All rights reserved</motion.p></React.Fragment>} className={"framer-q86csp"} data-framer-name={"© copyright"} fonts={["GF;DM Sans-regular"]} layoutDependency={layoutDependency} layoutId={"pgoIpWf7d"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "30px"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-N2Jnu.framer-f6g69t, .framer-N2Jnu .framer-f6g69t { display: block; }", ".framer-N2Jnu.framer-1oj2ru5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; min-height: 80px; overflow: visible; padding: 10px 0px 10px 0px; position: relative; width: 1240px; }", ".framer-N2Jnu .framer-q86csp { flex: none; height: auto; left: 50%; position: absolute; top: 49%; white-space: pre; width: auto; z-index: 1; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 1240
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerzLoQM6Baj: React.ComponentType<Props> = withCSS(Component, css, "framer-N2Jnu") as typeof Component;
export default FramerzLoQM6Baj;

FramerzLoQM6Baj.displayName = "Socials";

FramerzLoQM6Baj.defaultProps = {height: 80, width: 1240};

addFonts(FramerzLoQM6Baj, [{explicitInter: true, fonts: [{family: "DM Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v15/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAopxhS2f3ZGMZpg.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})